<template>
	<div>
		<b-modal ref="modal-speaker-edit" id="modal-speaker-edit" title="Edition">
			<!-- Media -->
			<b-media class="">
				<b-row>
					<b-col md="12" offset-md="4">
						<div align-h="center" class="mb-12">
							<b-avatar :src="photo" size="6rem"> </b-avatar>
							<br />
							<br />
							<div id="app">
								<input type="file" @change="onFileInput($event)" />
							</div>
						</div>
					</b-col>

					<b-col cols="12" class="mt-3">
						<table class="mt-2 mt-50 mt-xl-0 w-100">
							<tr>
								<th class="pb-50">
									<feather-icon icon="InfoIcon" class="mr-75" />
									<span class="font-weight-bold">Dernière connexion</span>
								</th>
								<td class="pb-50 text-muted">
									{{ getDate(speakerData.lastConnection) }}
								</td>
							</tr>

							<tr>
								<th class="pb-50">
									<feather-icon icon="CalendarIcon" class="mr-75" />
									<span class="font-weight-bold">Date de création</span>
								</th>
								<td class="pb-50 text-muted">
									{{ getDateCreation(speakerData.created_at) }}
								</td>
							</tr>
						</table>
					</b-col>
				</b-row>
			</b-media>

			<ValidationObserver ref="observer">
				<b-form>
					<ValidationProvider rules="required" v-slot="{ errors }">
						<label> Nom </label>
						<b-form-input
							v-model="speakerData.lastname"
							placeholder="Dupont"
						></b-form-input>
						<small class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
					<br />

					<ValidationProvider rules="required" v-slot="{ errors }">
						<label> Prénom </label>
						<b-form-input v-model="speakerData.firstname" placeholder="Dupont">
						</b-form-input>
						<small class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
					<br />

					<ValidationProvider rules="required|email" v-slot="{ errors }">
						<label> Email </label>
						<b-form-input v-model="speakerData.email" placeholder="Dupont">
						</b-form-input>
						<small class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
					<br />

					<ValidationProvider rules="required" v-slot="{ errors }">
						<label> Biographie </label>
						<b-form-textarea
							v-model="speakerData.biography"
							placeholder="Dupont"
						>
						</b-form-textarea>
						<small class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
					<br />

					<ValidationProvider rules="required" v-slot="{ errors }">
						<label> Spécialité </label>
						<b-form-input v-model="speakerData.speciality" placeholder="Dupont">
						</b-form-input>
						<small class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
					<br />
				</b-form>
			</ValidationObserver>

			<template #modal-footer>
				<div class="w-100">
					<b-button
						variant="primary"
						size="sm"
						class="float-right ml-1"
						@click="onSubmit"
					>
						Enregistrer
					</b-button>
					<b-button
						variant="danger"
						size="sm"
						class="float-right"
						@click="hideModal"
					>
						Fermer
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import {
	BModal,
	BButton,
	BForm,
	BAvatar,
	BMedia,
	BRow,
	BCol,
	BFormInput,
	BFormTextarea,
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";
import moment from "moment";
import { url } from "@/services/axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

extend("required", { message: "Ce champ ne peut pas être vide" });
extend("email", { message: "Ce champ doit être un email valide" });

export default {
	name: "SpeakerEditModal",

	props: {
		speakerData: Object,
	},

	components: {
		BModal,
		BButton,
		BForm,
		BAvatar,
		BRow,
		BMedia,
		BCol,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver,
	},

	mixins: [Admin],

	data() {
		return {
			show: false,
			photo: "",
			currentImage: undefined,
			optionsStatus: [
				{ key: 0, value: false },
				{ key: 1, value: true },
			],
		};
	},

	methods: {
		async onFileInput(event) {
			this.currentImage = event;
			const data = URL.createObjectURL(event.target.files[0]);
			this.photo = data;
		},

		async onSubmit() {
			const isValid = await this.$refs.observer.validate();
			if (isValid) {
				let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: true,
				});

				try {
					const response = await this.updateSpeaker(this.speakerData);
					if (response.status == 0) {
						//@dev alert pour dire qu'il y a eu un probleme
						console.log("erreur");
						loader.hide();
					} else {
						if (this.currentImage) {
							await this.uploadIMG(
								this.currentImage,
								response.datas.id,
								"PROFILE_PIC"
							);
						}

						loader.hide();
						this.form = [];
						this.$refs["modal-speaker-edit"].hide();
						this.$emit("updateSpeakers", response.datas);
					}
				} catch {
					loader.hide();
					this.$sweetError("AF-51");
				}
			}
		},

		hideModal() {
			this.$refs["modal-speaker-edit"].hide();
		},

		getDate(date) {
			if (date == null) {
				return "Aucune connexion établie";
			} else {
				moment.locale("fr");
				return moment(date)
					.startOf("day")
					.fromNow();
			}
		},

		getDateCreation(date) {
			moment.locale("fr");
			return moment(date).format("LL");
		},

		async loadImg() {
			let result = "";
			if (this.speakerData.profile_pics.length > 0) {
				const [img] = this.speakerData.profile_pics.filter((el) => {
					return el.size == "original";
				});
				result = url + "media/" + img.id;
			}
			return result;
		},

		checkstatus(status) {
			return status == 1 ? true : false;
		},
	},

	watch: {
		speakerData: async function() {
			this.photo = await this.loadImg();
		},
	},

	computed: {},
};
</script>

<style></style>