<template>
  <div>
    <AddHeaderTitle :Header="Header" />
    <AppHeaderInfoAdminList :infos="infos" />

    <b-row align-h="start">
      <b-button variant="primary " class="mb-2 mr-2" v-b-modal.modal-speaker-create>
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-left">Créer un speaker </span>
      </b-button>
    </b-row>

    <b-table striped hover :items="list" :fields="fields">
      <template #cell(status)="list">
        <b-row>
          <!-- <b-col>
            <b-badge
              pill
              :variant="`light-${getStatus(list.item.status)}`"
              class="text-capitalize"
            >
              {{ getStatusText(list.item.status) }}
            </b-badge>
          </b-col> -->
          <b-col>
            <b-form-group>
              <b-form-checkbox class="primary" name="check-button" switch v-model="list.item.status" value=1
                unchecked-value=0 @change="updateStatus(list.item)">
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>

              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template #cell(action)="list"> 
        <b-button v-b-modal.modal-speaker-edit @click="setDataSpeaker(list.item)" variant="flat-primary" size="sm"
          class="">
          <feather-icon icon="EditIcon" />
        </b-button>

        <b-button v-b-modal.modal-email variant="light" size="sm" @click="sendInfo(list.item)">
          <feather-icon icon="SendIcon" />
        </b-button>

        <b-button v-b-modal.modal-sm variant="flat-danger" size="sm" @click="sendInfo(list.item)">
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </template>
    </b-table>

    <b-modal id="modal-sm" centered size="sm" title="Information">
      <template #default>
        Êtes-vous sûr de vouloir supprimer le speaker {{ speaker.lastname }}
        {{ speaker.firstname }}?
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm" variant="danger" @click="deleteSpeakerId(speaker.id)">
          Supprimer
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-email" centered size="sm" title="Information">
      <template #default>
        Êtes-vous sûr de vouloir envoyer un email à {{ speaker.lastname }}
        {{ speaker.firstname }}?
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm" variant="primary" @click="sendEmailSpeakerId(speaker.id)">
          Envoyer
        </b-button>
      </template>
    </b-modal>

    <speaker-edit-modal @updateSpeakers="updateSpeaker" :speakerData="speakerData">
    </speaker-edit-modal>
    <speaker-create-modal @clicked="onCreate"> </speaker-create-modal>
  </div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import {
  BTable,
  BButton,
  BRow,
  //BBadge,
  BModal,
  BFormGroup,
  BFormCheckbox,
  BCol,
} from "bootstrap-vue";
import SpeakerCreateModal from "./speaker-create-modal.vue";
import SpeakerEditModal from "./speaker-edit-modal.vue";

import { Admin } from "@/mixins/admin.js";

export default {
  name: "Speakers",

  components: {
    BTable,
    AddHeaderTitle,
    AppHeaderInfoAdminList,
    BButton,
    //BBadge,
    BRow,
    SpeakerEditModal,
    SpeakerCreateModal,
    BModal,
    BFormGroup,
    BFormCheckbox,
    BCol,
  },

  data() {
    return {
      Header: {
        config: "Admin",
        listLink: "",
        list: "Speaker-liste",
      },
      infos: {
        title: "Liste des speakers",
        content: "Texte descptif ",
      },

      speakerData: {},
      speaker: {},
      statusArray: [
        {
          status: 1,
          badge: "primary",
          text: "Actif",
        },
        {
          status: 0,
          badge: "danger",
          text: "Inactif",
        },
      ],
      list: [],
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "lastname",
          label: "Nom",
          sortable: true,
        },
        {
          key: "firstname",
          label: "Prénom",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "biography",
          label: "Biographie",
          sortable: false,
        },
        {
          key: "speciality",
          label: "Spécialité",
          sortable: true,
        },
        {
          key: "status",
          label: "Statut",
          sortable: true,
        },
        {
          key: "Action",
          label: "Action",
          sortable: false,
        },
      ],
      busy: false,
      processing: false,
      SpeakerStatus: "",
    };
  },

  mixins: [Admin],

  mounted() {
    this.loadList();
  },

  methods: {
    onCreate(value) {
      this.list.push(value);
    },

    setDataSpeaker(speakerData) {
      this.speakerData = { ...speakerData };
    },

    async loadList() {
      const response = await this.getAllSpeaker();
      this.list = response.datas;
    },

    sendInfo(speakerInfo) {
      this.speaker = speakerInfo;
    },

    async deleteSpeakerId(idSpeaker) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      this.$bvModal.hide("modal-sm");
      try {
        const res = await this.deleteSpeaker(idSpeaker);
        if (res.status == 1) {
          this.$sweetNotif("Modification réussie");
          this.list = this.list.filter((speaker) => speaker.id !== idSpeaker);
        } else {
          this.$sweetError("AF-51");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-51");
      }
    },

    async sendEmailSpeakerId(idSpeaker) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      this.$bvModal.hide("modal-email");
      try {
        const res = await this.sendEmailSpeaker(idSpeaker);
        if (res.status == 1) {
          this.$sweetNotif("Email envoyé");
        } else {
          this.$sweetError("AF-51");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-51");
      }
    },

    getStatus(status) {
      const stat = this.statusArray.find((element) => element.status == status);
      return stat.badge;
    },

    getStatusText(status) {
      const stat = this.statusArray.find((element) => element.status == status);
      return stat.text;
    },

    updateSpeaker(speaker) {
      console.log(speaker);
      const index = this.list.findIndex((el) => el.id == speaker.id);
      this.list.splice(index, 1, speaker);
    },

    async updateStatus(item) {
      console.log("test ", item.status)
      // if (item.status) {
      //   this.SpeakerStatus = 1;
      // } else {
      //   this.SpeakerStatus = 0;
      // }
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      try {
        const datas = await this.updateSpeakerStatus(item);
        if (datas.status == 1) {
          this.$sweetNotif("Modification status réussie");
        } else {
          this.$sweetError("UPDATE-STATUS");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("UPDATE-STATUS-1");
      }
    },
    checkstatus(status) {
      console.log("status", status)
      return status == 1 ? true : false
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
