<template>
  <div>
    <b-modal ref="modal-speaker-create" id="modal-speaker-create" title="Créer un Speaker">
      <!-- Media -->
      <b-media class="">
        <b-row>
          <b-col md="12" offset-md="4">
            <div align-h="center" class="mb-12">
              <b-avatar :src="photo" size="6rem"> </b-avatar>
              <br />
              <br />
              <div id="app">
                <input type="file" @change="onFileInput($event)" />
              </div>
              <br />
            </div>
          </b-col>
        </b-row>
      </b-media>

      <ValidationObserver ref="observer">
        <b-form>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Nom </label>
            <b-form-input v-model="form.lastname" placeholder="Dupont"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Prénom </label>
            <b-form-input v-model="form.firstname" placeholder="Dupont">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <label> Email </label>
            <b-form-input v-model="form.email" placeholder="Dupont">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Biographie </label>
            <b-form-textarea v-model="form.biography" placeholder="Dupont">
            </b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Spécialité </label>
            <b-form-input v-model="form.speciality" placeholder="Dupont">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />
        </b-form>
      </ValidationObserver>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right ml-1" @click="onSubmit">
            Créer
          </b-button>
          <b-button variant="danger" size="sm" class="float-right" @click="hideModal">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BAvatar,
  BMedia,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("required", { message: "Ce champs ne peut pas être vide" });
extend("email", { message: "Ce champs doit être un email valide" });

export default {
  name: "SpeakerCreateModal",

  components: {
    BModal,
    BButton,
    BForm,
    BAvatar,
    BRow,
    BMedia,
    BCol,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [Admin],

  data() {
    return {
      show: false,
      form: {
        lastname: "",
        firstname: "",
        email: "",
        biography: "",
        speciality: "",
        status: "",
      },

      photo: "",
      currentImage: undefined,
      progress: 0,
      //  optionsStatus: [
      //   { key: false, value: 0 },
      //   {  key: true, value: 1 }
      // ],
    };
  },

  mounted() { },

  methods: {
    async onFileInput(event) {
      this.currentImage = event;
      const data = URL.createObjectURL(event.target.files[0]);
      this.photo = data;
    },

    async onSubmit() {
      console.log("tets valide", this.form);

      


      const isValid = await this.$refs.observer.validate();
      if (isValid) {

        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: true,
        });

        try {
          const response = await this.createSpeaker(this.form);
          if (response.status == 0) {
            //@dev alert pour dire qu'il y a eu un probleme
            console.log("erreur");
          } else {
            if (this.currentImage) {
              await this.uploadIMG(
                this.currentImage,
                response.datas.id,
                "PROFILE_PIC"
              );
            }
            loader.hide();
            this.$emit("clicked", response.datas);
            this.hideModal();
            this.$sweetNotif("Ajout réussi");
          }
        } catch {
          loader.hide();
          this.$sweetError("AF-51");
        }
      }
    },

    hideModal() {
      this.form = {};
      this.photo = "";
      this.currentImage = undefined
      this.$refs["modal-speaker-create"].hide();
    },
  },

  computed: {},
};
</script>

<style>
</style>
