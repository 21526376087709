var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-speaker-create",attrs:{"id":"modal-speaker-create","title":"Créer un Speaker"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right ml-1",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.onSubmit}},[_vm._v(" Créer ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.hideModal}},[_vm._v(" Fermer ")])],1)]},proxy:true}])},[_c('b-media',{},[_c('b-row',[_c('b-col',{attrs:{"md":"12","offset-md":"4"}},[_c('div',{staticClass:"mb-12",attrs:{"align-h":"center"}},[_c('b-avatar',{attrs:{"src":_vm.photo,"size":"6rem"}}),_c('br'),_c('br'),_c('div',{attrs:{"id":"app"}},[_c('input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.onFileInput($event)}}})]),_c('br')],1)])],1)],1),_c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Nom ")]),_c('b-form-input',{attrs:{"placeholder":"Dupont"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Prénom ")]),_c('b-form-input',{attrs:{"placeholder":"Dupont"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Email ")]),_c('b-form-input',{attrs:{"placeholder":"Dupont"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Biographie ")]),_c('b-form-textarea',{attrs:{"placeholder":"Dupont"},model:{value:(_vm.form.biography),callback:function ($$v) {_vm.$set(_vm.form, "biography", $$v)},expression:"form.biography"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Spécialité ")]),_c('b-form-input',{attrs:{"placeholder":"Dupont"},model:{value:(_vm.form.speciality),callback:function ($$v) {_vm.$set(_vm.form, "speciality", $$v)},expression:"form.speciality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }